import React from "react";
import {size} from "lodash";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import {IPollingQuestionOptions} from "modules/reducers";
import {IconArrowLeft, IconArrowRight, Modal, VoteButton} from "components";
import {
	MomentDiv,
	VideoModalWrap,
	VideoPrevNextWrapDiv,
	VideoPrevNextButton,
	VideoPlayerDiv,
	VideoContainer,
	VideoDescriptionWrapDiv,
	VoteButtonWrapDiv,
	VideoTitleWrapDiv,
	VideoTitleDiv,
} from "./VideoModalStyle";
import {theme} from "assets/theme";

interface IProps {
	isSelectedItem: boolean;
	modalIndex: number;
	videos: IPollingQuestionOptions[];
	roundLocked: boolean;
	weekID: number;
	onClose: () => null | void;
	handleModalIndexUpdate: (index: number) => void;
	handleSelectedItem: (
		week: number,
		value: number,
		videoNumber: number
	) => void;
}

export const VideoModal: React.FC<IProps> = ({
	onClose,
	handleModalIndexUpdate,
	handleSelectedItem,
	isSelectedItem,
	modalIndex,
	roundLocked,
	videos,
	weekID,
}) => {
	const viewPreviousVideo = () => {
		handleModalIndexUpdate(modalIndex - 1);
	};
	const isPrevVideoLinkDisabled = modalIndex === 0;
	const arrowLeftColor = isPrevVideoLinkDisabled
		? theme.text.grey
		: theme.text.green2;

	const viewNextVideo = () => {
		handleModalIndexUpdate(modalIndex + 1);
	};
	const isNextVideoLinkDisabled = modalIndex === size(videos) - 1;
	const arrowRightColor = isNextVideoLinkDisabled
		? theme.text.grey
		: theme.text.green2;

	const choseVideo = () => {
		handleSelectedItem(weekID, videos[modalIndex].id, modalIndex + 1);
	};
	return (
		<Modal onClose={onClose}>
			<VideoModalWrap>
				<VideoPrevNextWrapDiv>
					<VideoPrevNextButton
						onClick={viewPreviousVideo}
						disabled={isPrevVideoLinkDisabled}
					>
						<IconArrowLeft color={arrowLeftColor} />
					</VideoPrevNextButton>
				</VideoPrevNextWrapDiv>
				<VideoPlayerDiv>
					<VideoContainer>
						<ReactPlayerLoader
							videoId={videos[modalIndex].video}
							accountId="6057984925001"
						/>
					</VideoContainer>
					<VideoDescriptionWrapDiv>
						<VideoTitleWrapDiv>
							<MomentDiv>
								Moment {videos[modalIndex].id}
							</MomentDiv>
							<VideoTitleDiv>
								{videos[modalIndex].title}
							</VideoTitleDiv>
						</VideoTitleWrapDiv>
						{roundLocked ? null : (
							<VoteButtonWrapDiv>
								<VoteButton
									showText={true}
									isSelectedItem={isSelectedItem}
									onClick={choseVideo}
								/>
							</VoteButtonWrapDiv>
						)}
					</VideoDescriptionWrapDiv>
				</VideoPlayerDiv>
				<VideoPrevNextWrapDiv>
					<VideoPrevNextButton
						onClick={viewNextVideo}
						disabled={isNextVideoLinkDisabled}
					>
						<IconArrowRight color={arrowRightColor} />
					</VideoPrevNextButton>
				</VideoPrevNextWrapDiv>
			</VideoModalWrap>
		</Modal>
	);
};
