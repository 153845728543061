import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconPlayerInfo: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 16 16" fill="none" {...rest}>
		<g transform="translate(-2.000000, -2.000000)" fill={color}>
			<path d="M10,2.00048828 C14.4,2.00048828 18,5.60049 18,10.0004883 C18,14.4005 14.4,18.0005 10,18.0005 C5.6,18.0005 2,14.4005 2,10.0004883 C2,5.60049 5.6,2.00048828 10,2.00048828 Z M10,3.00049 C8.15,3.00049 6.35,3.75049 5.05,5.05049 C3.75,6.35049 3,8.15049 3,10.00049 C3,11.85049 3.75,13.6505 5.05,14.9505 C6.35,16.2505 8.15,17.00049 10,17.00049 C11.85,17.00049 13.65,16.2505 14.95,14.9505 C16.25,13.6505 17,11.85049 17,10.00049 C17,8.15049 16.25,6.35049 14.95,5.05049 C13.65,3.75049 11.85,3.00049 10,3.00049 Z M10.5,9.00049 L10.5,14.00049 L9.5,14.00049 L9.5,9.00049 L10.5,9.00049 Z M10,6.00049 L11,7.00049 L10,8.00049 L9,7.00049 L10,6.00049 Z" />
		</g>
	</SVG>
);

IconPlayerInfo.defaultProps = {
	width: 16,
	height: 16,
	color: "currentColor",
};
