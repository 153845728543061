import styled from "styled-components";

export const AdRect = styled.div`
	background: #d8d8d8;
	display: inline-block;
	margin: 40px auto 30px;
	width: 728px;
	height: 90px;

	@media (max-width: 728px) {
		width: 300px;
		height: 50px;
		margin: 15px auto;
	}
`;

export const AdRectSidebar = styled.div`
	background: #d8d8d8;
	margin: 0 auto 30px;
	width: 300px;
	height: 250px;

	@media (max-width: 640px) {
		height: 50px;
	}
`;
