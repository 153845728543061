import React from "react";
import TeamGirlsPic from "assets/img/team-girls.jpg";
import {
	BoldSpan,
	FormCompanionTitleDiv,
	FormCompanionVideoDiv,
	FormCompanionMainDiv,
	FormCompanion,
	FormCompanionUL,
	TeamGirlsImg,
} from "./FormWrapStyle";

export const FormCompanionWrap: React.FC = () => (
	<FormCompanion>
		<FormCompanionTitleDiv>
			What is Suncorp Team Girls?
		</FormCompanionTitleDiv>
		<FormCompanionVideoDiv>
			<TeamGirlsImg src={TeamGirlsPic} alt="Suncorp Team Girls" />
		</FormCompanionVideoDiv>
		<FormCompanionMainDiv>
			<BoldSpan>
				Vote for your chance to win the 2021 Suncorp Team Girls Grand Final Experience.
			</BoldSpan>
			<p>
        You’ll go in the draw to win the ultimate netball fan’s Grand Final Day Experience, 
        which includes:
			</p>
			<FormCompanionUL>
				<li>
          4 x VIP tickets to the 2021 Super Netball Grand Final {' '}
				</li>
				<li>Flights, transfers and one night’s accommodation,</li>
				<li>A money can’t buy experience on Grand Final day</li>
			</FormCompanionUL>
		</FormCompanionMainDiv>
	</FormCompanion>
);
