import React from "react";
import styled from "styled-components";
import FanHubLogo from "assets/img/fanhub-rev.svg";
import {theme} from "assets/theme";

const DOT_SPACE = 5;

interface IDot {
	showMobile?: boolean;
}

const FooterWrapper = styled.footer`
	background-color: #000;
	margin: 0 auto;
	padding: 50px;
	width: 100%;
	@media screen and (max-width: 600px) {
		padding: 10px;
	}
`;

const FooterContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;

	max-width: 1300px;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		max-width: calc(100% - 80px);
		margin: 50px auto;

		flex-direction: column;
		align-items: center;
	}
	@media screen and (max-width: 600px) {
		margin: 10px 0;
		max-width: 100%;
	}
`;

const LeftGroup = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		align-items: center;
	}
`;

const NavsWrapper = styled.div`
	display: flex;

	align-items: center;
	justify-content: flex-start;

	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		justify-content: center;

		margin-bottom: 20px;
		max-width: 200px;
	}
`;

const NavItem = styled.a`
	font-size: 12px;
	font-family: ${theme.font.radikal};
	font-weight: 600;
	text-decoration: none;

	color: ${theme.text.disabled};

	a& {
		cursor: pointer;
		:hover {
			text-decoration: underline;
		}
	}

	margin-right: ${DOT_SPACE * 2}px;
	:last-child {
		margin-right: 0;
	}

	@media screen and (max-width: 768px) {
		font-size: 10px;

		margin-right: ${DOT_SPACE}px;
		:last-child {
			margin-right: 0;
		}

		span& {
			margin-right: 0;
		}
	}
`;

const Dot = styled.div<IDot>`
	height: 3px;
	width: 3px;
	border-radius: 1.5px;

	background-color: ${theme.text.disabled};

	margin-right: ${2 * DOT_SPACE}px;

	@media screen and (max-width: 768px) {
		display: ${({showMobile}) => (showMobile ? "block" : "none")};
		margin-right: ${DOT_SPACE}px;
	}
`;

const FanHubLink = styled.a`
	color: unset;

	> svg {
		height: 40px;
		width: 121px;
	}
`;

const PrivacyStatementDiv = styled.div`
	font-size: 10px;
	font-family: ${theme.font.radikal};

	color: ${theme.text.disabled};

	width: 100%;
	max-width: 850px;
	text-align: left;

	margin: 50px 0 0;

	> span {
		font-weight: 600;
	}

	@media screen and (max-width: 768px) {
		text-align: center;
		margin: 0 0 50px;
	}
`;

const FanHubImg = styled.img`
	opacity: 0.4;
`;

export const Footer: React.FC = () => (
	<FooterWrapper>
		<FooterContentWrapper>
			<LeftGroup>
				<NavsWrapper>
					<NavItem as="span">
						Copyright &copy;2020 Netball Australia
					</NavItem>
					<Dot />
					<NavItem
						href="https://netball.com.au/about-netball-australia/governance/privacy-statement/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy Policy
					</NavItem>
					<Dot showMobile={true} />
					<NavItem
						href="https://supernetball.com.au/sites/supernetball/files/2021-07/Suncorp%20Team%20Girls%20Moment%20TCs_FINAL.pdf"
						rel="noopener noreferrer"
						target="_blank"
					>
						Terms &amp; Conditions
					</NavItem>
				</NavsWrapper>
				<PrivacyStatementDiv>
					<span>Privacy Collection Statement</span>: Suncorp
					(Australia) Pty Ltd respects your privacy. We will only use
					the information you provide for the purposes of processing
					and fulfilling your request or enquiry and, if you have
					consented on this form, sending you marketing material which
					may be of interest to you. We may disclose your information
					to an authorised Nissan dealer, our related companies and/or
					third parties associated with us for these purposes. Our
					Privacy Policy contains further details regarding how you
					can access or correct information we hold about you, how you
					can make a privacy related complaint, how that complaint
					will be dealt with and the extent to which your information
					may be disclosed to overseas recipients.
				</PrivacyStatementDiv>
			</LeftGroup>
			<FanHubLink
				href="https://www.fanhubmedia.com"
				target="_blank"
				rel="noopener noreferrer"
				title="Fanhub Media"
			>
				<FanHubImg
					src={FanHubLogo}
					alt="Fabhub Media Logo"
					width="121"
				/>
			</FanHubLink>
		</FooterContentWrapper>
	</FooterWrapper>
);
