import React from "react";
import styled from "styled-components";

interface IProps {
	color?: string;
}

const SVG = styled.svg`
	margin: 0 auto;
`;

export const IconArrowLeft: React.FC<IProps> = ({color}) => (
	<SVG viewBox={`0 0 24 24`} fill="none">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				fill={color}
				points="15.4 16.6 14 18 8 12 14 6 15.4 7.4 10.8 12"
			/>
		</g>
	</SVG>
);

IconArrowLeft.defaultProps = {
	color: "currentColor",
};
