import {createAction} from "redux-act";
import {
	ISubmitSweepstakes,
	IVoteSaveAnswers,
	IVoteSuccess,
} from "modules/types";

interface IVoteSuccessWithIndex {
	results: IVoteSuccess[];
	index: number;
}

export const fetchPolling = createAction();
export const fetchPollingSuccess = createAction<any>();
export const fetchPollingFailed = createAction<any>();

export const fetchPollingArchive = createAction();
export const fetchPollingArchiveSuccess = createAction<any>();
export const fetchPollingArchiveFailed = createAction<any>();

export const submitSweepstakesForm = createAction<ISubmitSweepstakes>();
export const startSweepstakesForm = createAction();
export const sweepstakesFormSuccess = createAction();
export const sweepstakesFormFailed = createAction<any>();

export const submitVoteForm = createAction<IVoteSaveAnswers>();
export const startVoteForm = createAction();
export const voteFormSuccess = createAction<IVoteSuccessWithIndex>();
