import styled from "styled-components";

export const Container = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;

	@media (max-width: 1240px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

export const ContainerWithSidebar = styled(Container)`
	@media (max-width: 640px) {
		flex-direction: column;
	}
`;
