import React from "react";
import {IconFacebook, IconTwitter} from "components";
import {theme} from "assets/theme";
import {
	SocialWrapDiv,
	SocialTextDiv,
	SocailIconsWrapDiv,
	SocailIconButton,
} from "./FormWrapStyle";
import {shareTwitter, shareFB} from "modules/utils/Share";

type IFormSocial = {
	selectedTitle: string;
};

export const FormSocial: React.FC<IFormSocial> = ({selectedTitle}) => {
	const shareFacebook = () => shareFB();
	const getShareTwitter = () => shareTwitter(selectedTitle);

	return (
		<SocialWrapDiv>
			<SocialTextDiv>Share your Moment</SocialTextDiv>
			<SocailIconsWrapDiv>
				<SocailIconButton onClick={getShareTwitter}>
					<IconTwitter
						color={theme.text.green2}
						height={40}
						width={40}
					/>
				</SocailIconButton>
				<SocailIconButton onClick={shareFacebook}>
					<IconFacebook
						color={theme.text.green2}
						height={40}
						width={40}
					/>
				</SocailIconButton>
			</SocailIconsWrapDiv>
		</SocialWrapDiv>
	);
};
