import {createAction} from "redux-act";
import {EAuthModal} from "modules/utils/enums";

export interface IAuthModal {
	showAuthModal: boolean;
	authModalToShow?: EAuthModal;
}

export interface IAuthModalToShow {
	authModalToShow: EAuthModal;
}

export const handleAuthModal = createAction<IAuthModal>();
export const handleAuthToShow = createAction<IAuthModalToShow>();

export const showForgotPasswordModal = createAction();
export const hideForgotPasswordModal = createAction();
export const resetForgotPasswordModal = createAction();

export const showPlayerModal = createAction<number>();
export const hiddenPlayerModal = createAction();
