import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import React, {lazy, Suspense, StrictMode} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {LastLocationProvider} from "react-router-last-location";
import {store} from "modules/store";
import {Provider} from "react-redux";
import {init, Integrations} from "@sentry/browser";
import {BASE_URL} from "modules/utils";
import {Preloader, PreloaderWrap, Main} from "components";
import {ErrorModal} from "components/Modal/ErrorModal";

const Landing = lazy(() => import("pages/Landing"));
const PreSeasonLanding = lazy(() => import("pages/PreSeasonLanding"));

const isPreSeason = false;

init({
	dsn:
		"https://2fe2773e29384fa5b11c34e573c4e1de@o151969.ingest.sentry.io/5301568",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 1,
	whitelistUrls: ["teamgirlsmoment.supernetball.com.au", ".fanhubmedia.com"],
	integrations: [
		new Integrations.Breadcrumbs({
			console: false,
		}),
	],
	blacklistUrls: [
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
});

ReactDOM.render(
	<StrictMode>
		<Provider store={store}>
			<Router basename={BASE_URL}>
				<LastLocationProvider>
					<Main>
						<Suspense
							fallback={
								<React.Fragment>
									<PreloaderWrap>
										<Preloader />
										<Preloader />
									</PreloaderWrap>
								</React.Fragment>
							}
						>
							<Switch>
								{isPreSeason ? (
									<Route
										exact={true}
										path="/"
										component={PreSeasonLanding}
									/>
								) : (
									<Route
										exact={true}
										path="/"
										component={Landing}
									/>
								)}
								{isPreSeason ? (
									<Route
										path="*"
										component={PreSeasonLanding}
									/>
								) : (
									<Route path="*" component={Landing} />
								)}
							</Switch>
						</Suspense>
						<ErrorModal />
					</Main>
				</LastLocationProvider>
			</Router>
		</Provider>
	</StrictMode>,
	document.getElementById("root")
);
