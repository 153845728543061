import {call, put} from "redux-saga/effects";
import {Api, ApiError} from "modules/utils";
import {fetchHelpPagesFailed, fetchHelpPagesSuccess} from "modules/actions";

export const fetchHelpPagesSaga = function* () {
	try {
		const response = yield call(Api.JSON.help_pages);

		ApiError.CHECK(response);

		yield put(fetchHelpPagesSuccess(response));
	} catch (e) {
		yield put(fetchHelpPagesFailed(e));
	}
};
