import { createReducer } from 'redux-act';

const defaultState = {
	faqs: [],
};

export interface IHelpReducer {
	faqs?: object[];
}

export const help = createReducer<IHelpReducer>({}, defaultState);
