import React, {useState, useEffect} from "react";
import {size} from "lodash";
import {IPollingItem} from "modules/reducers";
import {IconArrowLeft, IconArrowRight} from "components";
import {
	RoundButton,
	RoundNavDiv,
	RoundPrevNextButton,
	RoundNavUL,
	RoundNavLi,
} from "./RoundNavStyles";

interface IRoundNav {
	currentRoundIndex: number;
	isMobile?: boolean;
	rounds: IPollingItem[];
	updateRound: (roundIndex: number) => void;
}

const roundsToShowInNav = (index, rounds) => {
	const roundsLength = size(rounds);
	if (index < 4) {
		return rounds.slice(0, 4);
	} else if (index + 2 > roundsLength) {
		return rounds.slice(roundsLength - 4, roundsLength);
	} else {
		return rounds.slice(index - 2, index + 2);
	}
};

const roundIndexes = (rounds: IPollingItem[]) =>
	rounds.reduce((roundObj, round, roundIndex) => {
		return {
			...roundObj,
			[round.number]: roundIndex,
		};
	}, {});

export const RoundNav: React.FC<IRoundNav> = ({
	currentRoundIndex,
	isMobile = false,
	rounds,
	updateRound,
}) => {
	const [activeScrollIndex, setActiveScorllIndex] = useState(
		currentRoundIndex
	);
	useEffect(() => {
		setActiveScorllIndex(currentRoundIndex);
	}, [currentRoundIndex]);

	const updatePrev = () => {
		setActiveScorllIndex(activeScrollIndex - 1);
	};
	const updateNext = () => {
		if (activeScrollIndex > 3) {
			setActiveScorllIndex(activeScrollIndex + 1);
		} else {
			setActiveScorllIndex(activeScrollIndex + 3);
		}
	};
	const roundsToRender = isMobile
		? roundsToShowInNav(activeScrollIndex, rounds)
		: rounds;
	const roundNavDivSelector = isMobile ? "show-mob" : "show-desktop";
	return (
		<RoundNavDiv className={roundNavDivSelector}>
			{isMobile && (
				<RoundPrevNextButton
					aria-label={"Previous Rounds"}
					disabled={!(activeScrollIndex - 4 >= 0)}
					onClick={updatePrev}
				>
					<IconArrowLeft />
				</RoundPrevNextButton>
			)}
			<RoundNavUL>
				{roundsToRender.map((round) => {
					const roundIndex = roundIndexes(rounds)[round.number];
					const onClick = () => {
						updateRound(roundIndex);
					};
					const status =
						roundIndex === currentRoundIndex
							? "current"
							: round.status;

					return (
						<RoundNavLi key={round.id}>
							<RoundButton
								aria-label={"Select Round"}
								onClick={onClick}
								status={status}
								disabled={status === "scheduled"}
							>
								{round.number}
							</RoundButton>
						</RoundNavLi>
					);
				})}
			</RoundNavUL>
			{isMobile && (
				<RoundPrevNextButton
					aria-label={"Next Rounds"}
					onClick={updateNext}
					disabled={!(size(rounds) > activeScrollIndex + 1)}
				>
					<IconArrowRight />
				</RoundPrevNextButton>
			)}
		</RoundNavDiv>
	);
};
