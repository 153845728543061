const primary = "#00B2A9";
export const theme = {
	primary: {
		primary,
	},
	borders: {
		active: "#E3DADA",
		disabled: "#F1EDED",
	},
	font: {
		arial: "Arial, sans-serif",
		radikal: "Radikal, sans-serif",
	},
	text: {
		activeGrey: "#B8B8B8",
		black: "#000000",
		darkGrey: "#262424",
		disabled: "#E3DADA",
		green1: "#03A29A",
		green2: primary,
		green3: "#14B1A9",
		grey: "#939393",
		white: "#FFFFFF",
	},
	buttons: {
		primary,
		disabled: "#E3DADA",
	},
	form: {
		border: "#E3DADA",
		disabled: "#E3DADA",
		error: "red",
	},
};
