export enum EAuthModal {
	REGISTRATION = "registration",
	LOGIN = "login",
}

export enum MatchStatus {
	Scheduled = "scheduled",
	Active = "playing",
	Complete = "complete",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Active = "active",
	Complete = "complete",
}

export enum PlayerStatus {
	Playing = "playing",
	NotPlaying = "not-playing",
	Injured = "injured",
	Uncertain = "uncertain",
}

export enum LeagueStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum LeagueClass {
	Overall = "overall",
	Regular = "regular",
	Facebook = "facebook",
	Twitter = "twitter",
	One = "one",
	ThreeLions = "three_lions",
}

export enum LeagueType {
	OPEN = "open",
	H2H = "h2h",
	TOURNAMENT = "tournament",
}
