import {createReducer} from "redux-act";
import {size} from "lodash";
import {
	fetchPollingSuccess,
	submitSweepstakesForm,
	startSweepstakesForm,
	sweepstakesFormSuccess,
	sweepstakesFormFailed,
	submitVoteForm,
	startVoteForm,
	voteFormSuccess,
	fetchPollingArchiveSuccess,
} from "modules/actions";

const clearErrorMsg = {
	code: 0,
	text: "",
};

const pollingState = {
	items: [],
	formStatus: "",
	voteFormStatus: "",
	voteFormStatusByID: {},
	voteResults: {},
	formError: clearErrorMsg,
};

const defaultState = {
	...pollingState,
	archive: pollingState,
};

export type StatusTypes = "complete" | "scheduled" | "active" | "current";

export interface IKeyWithNumber {
	[key: string]: number;
}

export interface IKeyWithString {
	[key: string]: string;
}

export interface IFormData {
	first_name: string;
	last_name: string;
	email: string;
	sponsor_opt_in: number;
	email_opt_in: number;
	terms: number;
}

export interface IPollingQuestionOptions {
	id: number;
	title: string;
	video: string;
	correct?: number;
	videoIndex?: number;
}

interface IPollingQuestion {
	has_answer: boolean;
	id: number;
	locked: number;
	options: IPollingQuestionOptions[];
	position: number;
	stats: {
		polling: IKeyWithNumber;
	};
	title: string;
	view: string;
	view_number: number;
}

export interface IVoteSuccess {
	option_id: number;
	question_id: number;
	stats: IKeyWithNumber;
}

export interface IPollingItem {
	date_from: string;
	date_to: string;
	display: string;
	for_publish: number;
	game: string;
	hero_image: string;
	hero_image_description: string;
	id: number;
	locked: number;
	lockout: string;
	name: string;
	number: number;
	prize: string;
	prize_winner: string;
	questions: IPollingQuestion[];
	show_rankings: number;
	status: StatusTypes;
	timer: number;
	type: string;
}

export interface IFormError {
	code: number;
	text: string;
}

export interface IPollingData {
	items?: IPollingItem[];
	formStatus?: string;
	voteFormStatus?: string;
	voteResults: IKeyWithNumber;
	voteFormStatusByID?: IKeyWithString;
	formError?: IFormError;
}

export interface IPollingReducer {
	items?: IPollingItem[];
	formStatus?: string;
	voteFormStatus?: string;
	voteResults: IKeyWithNumber;
	voteFormStatusByID?: IKeyWithString;
	formError?: IFormError;
	archive: IPollingData;
}

export interface ISweepStakesAnswers {
	question_id: string;
	first_name: string;
	last_name: string;
	email: string;
	email_opt_in: number;
	terms: number;
	user_key?: string;
	sponsor_opt_in: number;
	selectedItem: string;
}

export interface IVoteSaveAnswers {
	question_id: number;
	option_id: number;
	user_key: string;
}

export interface ISubmitSweepstakes {
	formData: ISweepStakesAnswers;
	index: number;
}

const getVoteCountByID = (items: IPollingItem[]) =>
	items.reduce((acc, cur) => {
		return {
			...acc,
			[cur.id]: size(cur.questions) ? cur.questions[0].stats.polling : {},
		};
	}, {});

const getVoteFormStatusByID = (items: IPollingItem[]) =>
	items.reduce((acc, cur) => {
		return {
			...acc,
			[cur.id]: "",
		};
	}, {});

const firstRoundToShow = process.env.REACT_APP_FIRST_ROUND_TO_SHOW || 0;
const lastRoundToShow = process.env.REACT_APP_LAST_ROUND_TO_SHOW || 0;
export const polling = createReducer<IPollingReducer>({}, defaultState)
	.on(fetchPollingSuccess, (state, payload) => ({
		...state,
		items: payload
			.filter((item) => item.id >= firstRoundToShow)
			.filter((item) => item.id <= lastRoundToShow),
		voteResults: getVoteCountByID(payload),
		voteFormStatusByID: getVoteFormStatusByID(payload),
	}))
	.on(fetchPollingArchiveSuccess, (state, payload) => ({
		...state,
		archive: {
			...state.archive,
			items: payload
				.filter((item) => item.id >= firstRoundToShow)
				.filter((item) => item.id <= lastRoundToShow),
			voteResults: getVoteCountByID(payload),
			voteFormStatusByID: getVoteFormStatusByID(payload),
		},
	}))
	.on(startSweepstakesForm, (state) => ({
		...state,
		formStatus: "",
		formError: clearErrorMsg,
	}))
	.on(submitSweepstakesForm, (state) => ({
		...state,
		formStatus: "saving",
	}))
	.on(sweepstakesFormSuccess, (state) => ({
		...state,
		formStatus: "success",
	}))
	.on(sweepstakesFormFailed, (state, err) => ({
		...state,
		formError: err.text,
		formStatus: "error",
		voteFormStatusByID: {
			...state.voteFormStatusByID,
			[err.index + 1]: "error",
		},
	}))
	.on(startVoteForm, (state) => ({
		...state,
		voteFormStatus: "",
	}))
	.on(submitVoteForm, (state) => ({
		...state,
		voteFormStatus: "saving",
	}))
	.on(voteFormSuccess, (state, payload) => ({
		...state,
		voteFormStatus: "success",
		voteResults: {
			...state.voteResults,
			[payload.index + 1]: payload.results[0].stats,
		},
		voteFormStatusByID: {
			...state.voteFormStatusByID,
			[payload.index + 1]: "success",
		},
	}));
