import {createReducer} from "redux-act";
import {
	handleAuthModal,
	handleAuthToShow,
	hiddenPlayerModal,
	showPlayerModal,
	resetForgotPasswordModal,
	showForgotPasswordModal,
	hideForgotPasswordModal,
} from "modules/actions";
import {IAuthModal} from "modules/types";
import {EAuthModal} from "modules/utils/";

const defaultState = {
	showTeamNameModal: false,
	showAuthModal: false,
	authModalToShow: EAuthModal.REGISTRATION,
	forgotPasswordModal: {
		isShow: false,
	},
	playerModalId: 0,
};

export interface IModalsReducer {
	showTeamNameModal: boolean;
	showAuthModal: boolean;
	authModalToShow: EAuthModal;
	playerModalId: number;
	forgotPasswordModal: {
		isShow: boolean;
		error?: string;
		success?: boolean;
	};
}

export const modals = createReducer<IModalsReducer>({}, defaultState)
	.on(handleAuthModal, (state, payload: IAuthModal) => ({
		...state,
		...payload,
	}))
	.on(handleAuthToShow, (state, payload) => ({
		...state,
		...payload,
	}))
	.on(showPlayerModal, (state, payload) => ({
		...state,
		playerModalId: payload,
	}))
	.on(hiddenPlayerModal, (state) => ({
		...state,
		playerModalId: 0,
	}))
	.on(showForgotPasswordModal, (state) => ({
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			isShow: true,
		},
	}))
	.on(hideForgotPasswordModal, (state) => ({
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			isShow: false,
			success: undefined,
			error: undefined,
		},
	}))
	.on(resetForgotPasswordModal, (state) => ({
		...state,
		forgotPasswordModal: {
			...state.forgotPasswordModal,
			success: undefined,
			error: undefined,
		},
	}));
