import React from "react";
import {IFormError, IPollingQuestionOptions} from "modules/types";
import {FormErrorDiv, FormThanksDiv, FormMomentDiv} from "./FormWrapStyle";
import {FormTopContent} from "./FormTopContent";
import {FormSocial} from "./FormSocial";

type IFormComplete = {
	roundLocked: boolean;
	winner: IPollingQuestionOptions;
	formError: IFormError;
	selectedIDContent: (
		selectedID: number,
		section: string,
		selectedTitle: string
	) => string;
	selectedID: number;
	selectedTitle: string;
};

const thankYouText = (roundLocked: boolean) =>
	roundLocked
		? "Click on the current round above to vote for your play of the week."
		: "Thank you for voting for your Suncorp Team Girls Moment of the week! You are now in the running for some fantastic prizes.";

const getSelectedTitle = (
	roundLocked: boolean,
	selectedTitle: string,
	winner: IPollingQuestionOptions
) => (roundLocked ? winner.title : selectedTitle);

export const FormComplete: React.FC<IFormComplete> = ({
	roundLocked,
	formError,
	selectedID,
	selectedIDContent,
	selectedTitle,
	winner,
}) => {
	if (formError.code > 0) {
		return (
			<div>
				<FormErrorDiv>
					You have already entered a vote for this week. Please come
					back again next week!
				</FormErrorDiv>
				<FormThanksDiv>{thankYouText}</FormThanksDiv>
			</div>
		);
	} else {
		return (
			<div>
				{roundLocked ? (
					<FormMomentDiv selectedID={1}>
						Round Winner: {winner.title}
					</FormMomentDiv>
				) : (
					<FormTopContent
						selectedID={selectedID}
						selectedTitle={selectedTitle}
						selectedIDContent={selectedIDContent}
					/>
				)}
				<FormThanksDiv>{thankYouText(roundLocked)}</FormThanksDiv>
				{!roundLocked && (
					<FormSocial
						selectedTitle={getSelectedTitle(
							roundLocked,
							selectedTitle,
							winner
						)}
					/>
				)}
			</div>
		);
	}
};
