import React from "react";
import styled from "styled-components";
import {IconChecked, IconUnchecked} from "components";
import {theme} from "assets/theme";

const VoteItemButton = styled.button`
	background-color: transparent;
	border: none;
	height: 30px;
	margin: 0 auto;
	padding: 0;
	width: 30px;
`;

const VoteDescriptionDiv = styled.div`
	color: ${theme.text.green2};
	font-family: ${theme.font.radikal};
	font-size: 10px;
	font-weight: 700;
	line-height: 12px;
	text-transform: uppercase;
	width: 80px;
	text-align: center;
	margin: 0 auto 12px;
`;

interface IProps {
	isSelectedItem: boolean;
	showText?: boolean;
	onClick: () => null | void;
}

export const VoteButton: React.FC<IProps> = ({
	isSelectedItem,
	showText = false,
	onClick,
}) => (
	<React.Fragment>
		{showText && (
			<VoteDescriptionDiv>
				This is <br />
				my moment
			</VoteDescriptionDiv>
		)}
		<VoteItemButton aria-label={"Select Video"} onClick={onClick}>
			{isSelectedItem ? (
				<IconChecked color={theme.text.green1} />
			) : (
				<IconUnchecked color={theme.text.green1} />
			)}
		</VoteItemButton>
	</React.Fragment>
);
