import React from "react";
import styled from "styled-components";
import LeaderboardImg from "assets/img/leaderboard.jpeg";

const AdWrapDiv = styled.div`
	margin: 0 auto;
	padding: 50px;
	width: 100%;
	@media screen and (max-width: 600px) {
		padding: 10px;
	}
`;

const AdLink = styled.a``;

const AdImg = styled.img`
	max-width: 100%;
`;

export const AdLeaderBoard: React.FC = () => (
	<AdWrapDiv>
		<AdLink
			href="https://www.suncorp.com.au/about-us/partnerships/teamgirls.html?cmpid=SUN:PI:NPS:DIS:20200801:2995"
			target="_blank"
			rel="noopener noreferrer"
		>
			<AdImg src={LeaderboardImg} alt="Suncorp Team Girls" />
		</AdLink>
	</AdWrapDiv>
);
