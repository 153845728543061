import styled from "styled-components";
import {theme} from "assets/theme";

export const MomentDiv = styled.div`
	color: ${theme.text.grey};
	font-family: ${theme.font.radikal};
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 1.63px;
	line-height: 12px;
	text-transform: uppercase;
`;

export const VideoPrevNextWrapDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 120px;
	@media screen and (max-width: 600px) {
		display: none;
	}
`;

export const VideoTitleWrapDiv = styled.div`
	padding: 20px;
`;

export const VideoTitleDiv = styled.div`
	color: ${theme.text.green2};
	font-family: ${theme.font.radikal};
	font-size: 20px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 24px;
	text-transform: uppercase;
`;

export const VideoPrevNextButton = styled.button`
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	flex-direction: column;
	height: 60px;
	margin: 0 auto;
	justify-content: center;
	width: 60px;
`;

export const VideoPlayerDiv = styled.div`
	background-color: #fff;
	padding: 5px 5px 0 5px;
	width: calc(100% - 120px);

	@media screen and (max-width: 600px) {
		width: 100%;
	}
`;

export const VideoContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
`;

export const VideoContainerIframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const VideoDescriptionWrapDiv = styled.div`
	display: flex;
	height: 120px;
	justify-content: space-between;
`;

export const VoteButtonWrapDiv = styled.div`
	background-color: #eef9f9;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 10px;
	width: 140px;
`;

export const VideoModalWrap = styled.div`
	display: flex;
`;
