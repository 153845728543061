import React from "react";
import {size} from "lodash";
import {
	IPollingItem,
	IPollingQuestionOptions,
	IKeyWithNumber,
} from "modules/reducers";
import {VoteButton} from "components";
import {
	CurrentRoundUl,
	CurrentRoundLi,
	CurrentImageButton,
	CurrentImagePlay,
	MomentTextDiv,
	MomentTitleDiv,
	NoVideosDiv,
	VideoDescriptionDiv,
	VoteButtonWrapDiv,
	VoteResultsDiv,
	VotePercentageDiv,
	VotePercentageWrapDiv,
} from "./CurrentRoundStyle";
import {VideoOverLay} from "./VideoOverLay";

interface IRoundNav {
	content: IPollingItem;
	roundLocked: boolean;
	selectedItem: string;
	showVotes: boolean;
	videos: IPollingQuestionOptions[];
	voteResults: IKeyWithNumber;
	winner: IPollingQuestionOptions;
	handleOpenModal: (index: number) => void;
	handleSelectedItem: (
		week: number,
		value: number,
		videoNumber: number
	) => void;
}

const getVideoImgURL = (videoID: string) =>
	`https://supernetball.com.au/sites/supernetball/files/migrated_images/video_preview_imgs/${videoID}.jpg`;

const getVideoTitle = (videoTitle: string, maxChar: number) =>
	size(videoTitle) > maxChar + 1
		? `${videoTitle.substring(0, maxChar)}...`
		: videoTitle;

const getVotePercentage = (
	currentVotes: number,
	videoID: number,
	voteTotals: number
) =>
	size(Object.keys(currentVotes))
		? Math.round(
				isNaN(currentVotes[videoID] / voteTotals)
					? 0
					: (currentVotes[videoID] / voteTotals) * 100
		  )
		: 0;

const getVotePercentageWidth = (showVotes: boolean, votePercentage: number) =>
	showVotes ? `${votePercentage}%` : "100%";

const getVoteTotals = (currentVotes: number) =>
	Object.keys(currentVotes)
		.map((key) => currentVotes[key])
		.reduce((a, b) => a + b, 0);

const getVideoSelector = (videosLength: number) => `vid-${videosLength}`;

export const CurrentRound: React.FC<IRoundNav> = ({
	content,
	roundLocked,
	selectedItem,
	showVotes,
	videos,
	voteResults,
	winner,
	handleOpenModal,
	handleSelectedItem,
}) => {
	const currentVotes = voteResults[content.id];
	const voteTotals = getVoteTotals(currentVotes);
	const videoItemSelector = getVideoSelector(size(videos));
	const selectedItemCheckLocked = roundLocked
		? `${content.id}-${winner.id}-${winner.videoIndex! + 1}`
		: selectedItem;
	return size(videos) ? (
		<div>
			<CurrentRoundUl>
				{videos.map((video, videoIndex) => {
					const videoImg = getVideoImgURL(video.video);
					const videoNumber = videoIndex + 1;
					const openModal = () => {
						handleOpenModal(videoIndex);
					};
					const choseVideo = () => {
						handleSelectedItem(content.id, video.id, videoNumber);
					};

					const isSelectedItem =
						selectedItemCheckLocked ===
						`${content.id}-${video.id}-${videoNumber}`;

					const votePercentage = getVotePercentage(
						currentVotes,
						video.id,
						voteTotals
					);
					return (
						<CurrentRoundLi
							key={video.id}
							className={videoItemSelector}
						>
							{!isSelectedItem &&
							selectedItemCheckLocked !== "" ? (
								<VideoOverLay
									selector={videoItemSelector}
									openModal={openModal}
									choseVideo={choseVideo}
								/>
							) : null}
							<CurrentImageButton
								style={{backgroundImage: `url(${videoImg})`}}
								aria-label={"View Video"}
								onClick={openModal}
							>
								<CurrentImagePlay />
							</CurrentImageButton>
							<VideoDescriptionDiv>
								<div>
									<MomentTextDiv>
										{`${content.name} - Moment ${videoNumber}`}
									</MomentTextDiv>
									<MomentTitleDiv
										title={video.title}
										className={"show-desktop"}
									>
										{getVideoTitle(video.title, 65)}
									</MomentTitleDiv>
									<MomentTitleDiv
										title={video.title}
										className={"show-mobile"}
									>
										{getVideoTitle(video.title, 53)}
									</MomentTitleDiv>
								</div>
								<VoteButtonWrapDiv>
									{showVotes ? (
										<VoteResultsDiv>{`${votePercentage}%`}</VoteResultsDiv>
									) : (
										<VoteButton
											isSelectedItem={isSelectedItem}
											onClick={choseVideo}
										/>
									)}
								</VoteButtonWrapDiv>
							</VideoDescriptionDiv>
							<VotePercentageWrapDiv>
								<VotePercentageDiv
									style={{
										width: getVotePercentageWidth(
											showVotes,
											votePercentage
										),
									}}
								/>
							</VotePercentageWrapDiv>
						</CurrentRoundLi>
					);
				})}
			</CurrentRoundUl>
		</div>
	) : (
		<NoVideosDiv>
			No videos have been uploaded for this round, please check back after
			the round is complete.
		</NoVideosDiv>
	);
};
