import {IStore} from "modules/types";
import {createSelector} from "reselect";

export const getAuthModalState = ({modals: {showAuthModal}}: IStore) =>
	showAuthModal;

export const whatAuthModalToShow = ({modals: {authModalToShow}}: IStore) =>
	authModalToShow;

export const getPlayerModalId = ({modals: {playerModalId}}: IStore) =>
	playerModalId;

export const getEditTeamNameModalState = ({
	modals: {showTeamNameModal},
}: IStore) => showTeamNameModal;

export const getForgotPasswordModal = ({
	modals: {forgotPasswordModal},
}: IStore) => forgotPasswordModal;

export const isForgotPasswordModalVisible = createSelector(
	getForgotPasswordModal,
	({isShow}) => isShow
);

export const isForgotPasswordRequestedSuccess = createSelector(
	getForgotPasswordModal,
	({success}) => Boolean(success)
);

export const getForgotPasswordRequestedError = createSelector(
	getForgotPasswordModal,
	({error}) => error
);
