import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconFacebook: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 8 16" fill="none" {...rest}>
		<g transform="translate(-6.000000, -2.000000)" fill={color}>
			<path d="M10.9777778,7.33333333 L13.4666667,7.33333333 L13.1555556,10 L10.9777778,10 L10.9777778,18 L7.75,18 L7.75,10 L6,10 L6,7.33333333 L7.75,7.33333333 L7.75,5.54166667 C7.75,4.37499417 8.0222195,3.49305854 8.56666667,2.89583333 C9.11111383,2.29860812 10.0185122,2 11.2888889,2 L13.4666667,2 L13.4666667,4.66666667 L12.1444444,4.66666667 C11.6518494,4.66666667 11.33426,4.74999917 11.1916667,4.91666667 C11.0490734,5.08333417 10.9777778,5.36110917 10.9777778,5.75 L10.9777778,7.33333333 Z" />
		</g>
	</SVG>
);
IconFacebook.defaultProps = {
	width: 8,
	height: 16,
	color: "currentColor",
};
