import React from "react";
import styled from "styled-components";
import cross from "assets/img/icons/cross.svg";

export const ModalWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.8);
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const ModalInner = styled.div`
	width: 90%;
	max-width: 1200px;
	position: relative;
	box-sizing: border-box;
	max-height: 100%;
	overflow: auto;
	@media (max-width: 640px) {
		width: 90%;
	}
`;

const CloseDivWrap = styled.div`
	display: flex;
	width: 100%;
`;

const CloseDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: 0 auto;
	width: calc(100% - 240px);
	@media screen and (max-width: 600px) {
		width: 100%;
	}
`;

const CloseButton = styled.button`
	background: none;
	border-radius: 0;
	border: none;
`;

const CloseSpacer = styled.div`
	width: 120px;
	@media screen and (max-width: 600px) {
		display: none;
	}
`;

interface IProps {
	children?: React.ReactNode;
	onClose: () => null | void;
}

export const Modal: React.FC<IProps> = ({
	children = null,
	onClose,
	...rest
}: IProps) => (
	<ModalWrapper {...rest}>
		<ModalInner>
			<CloseDivWrap>
				<CloseSpacer />
				<CloseDiv>
					<CloseButton onClick={onClose}>
						<img src={cross} alt="Close" />
					</CloseButton>
				</CloseDiv>
				<CloseSpacer />
			</CloseDivWrap>
			{children}
		</ModalInner>
	</ModalWrapper>
);
