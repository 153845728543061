import { createReducer } from 'redux-act';
import { showGlobalError, clearGlobalError } from 'modules/actions';
import { IError } from "modules/types";

interface IErrorReducer {
	text?: string;
}

export const error = createReducer<IErrorReducer>({}, {})
	.on(showGlobalError, (state: IErrorReducer, { text }: IError) => ({ text }))
	.on(clearGlobalError, () => ({}));
