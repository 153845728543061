import React from "react";
import {Header} from "components/Header";
import {Footer} from "components/Footer";
import styled from "styled-components";

const Wrapper = styled.div`
	min-height: 100%;
`;

const GridWrap = styled.div`
	margin: 0 auto;
	max-width: 1300px;
	width: 100%;
`;

export const Main: React.FC = ({children}) => (
	<React.Fragment>
		<Wrapper>
			<GridWrap>
				<Header />
				<main>{children}</main>
			</GridWrap>
		</Wrapper>
		<Footer />
	</React.Fragment>
);
