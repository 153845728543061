import {isString, get} from "lodash";
import {SagaIterator} from "redux-saga";
import {put, call} from "redux-saga/effects";
import {ISagaAction} from "modules/types";
import {ApiError} from "modules/utils";
import {showGlobalError} from "modules/actions";

type TPayload = Error | string;

const defaultErrorHandler = async (payload: TPayload) => {
	if (isString(payload)) {
		throw new Error(payload);
	}

	if (isString(payload.message)) {
		throw new Error(payload.message);
	}
};

const onError = {
	*[ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
		yield call(defaultErrorHandler, error);
	},
};

export const errorsManagerSaga = function* ({
	payload,
}: ISagaAction<TPayload>): SagaIterator {
	const error_code = get(payload, "code");

	try {
		const onErrorHandler = get(onError, error_code, defaultErrorHandler);
		yield call(onErrorHandler, payload);
	} catch (err) {
		yield put(
			showGlobalError({
				text: err.message,
				code: error_code || 0,
			})
		);
	}
};
