import React from "react";
import {isBoolean} from "lodash";
import {IFormData} from "modules/types";
import {theme} from "assets/theme";
import {IconChecked, IconUnchecked} from "components";
import {useForm, SubmitHandler} from "react-hook-form";
import {FormTopContent} from "./FormTopContent";

import {
	ErrorSpan,
	FormCheckboxLabel,
	FormCheckboxLabelSpan,
	FormCheckboxLabelLink,
	FormInput,
	FormInputCheckbox,
	FormInputSubmit,
	FormFieldCheckboxWrapDiv,
	FormFieldWrapDiv,
	FormLabel,
} from "./FormWrapStyle";

interface IFormContent {
	isDisabled: boolean;
	selectedID: number;
	handleFormSubmit: (formData: IFormData) => void;
	selectedTitle: string;
	selectedIDContent: (
		selectedID: number,
		section: string,
		selectedTitle: string
	) => string;
}

const defaultFormData = {
	first_name: "",
	last_name: "",
	email: "",
	sponsor_opt_in: 0,
	email_opt_in: 0,
	terms: 0,
};

const getFormattedFormData = (data: IFormData, dataKeys: string[]) =>
	dataKeys.reduce((prev, cur) => {
		return {
			...prev,
			[cur]: isBoolean(data[cur])
				? booleanToNumber(data[cur])
				: data[cur],
		};
	}, defaultFormData);

type FieldError = {
	type: string;
	message?: string;
};

const booleanToNumber = (bool: boolean) => (bool ? 1 : 0);
const checkError = (error: boolean | undefined | FieldError) =>
	error !== undefined;

const formData = [
	{
		label: "First Name",
		name: "first_name",
		type: "text",
		validations: {required: true, minLength: 2},
	},
	{
		label: "Surname",
		name: "last_name",
		type: "text",
		validations: {required: true, minLength: 2},
	},
	{
		label: "Email",
		name: "email",
		type: "email",
		validations: {required: true, minLength: 2},
	},
];
const formCheckboxes = [
	{
		label: "Keep up to date on the Suncorp Team Girls.",
		name: "sponsor_opt_in",
		type: "checkbox",
		validations: {required: false},
	},
	{
		label: "I accept the Terms and Conditions. You can view them",
		name: "terms",
		type: "checkbox",
		validations: {required: true},
	},
];

export const FormContent: React.FC<IFormContent> = ({
	handleFormSubmit,
	isDisabled,
	selectedID,
	selectedTitle,
	selectedIDContent,
}) => {
	const {register, handleSubmit, errors, watch} = useForm<IFormData>();
	const onSubmit: SubmitHandler<IFormData> = (data) => {
		const dataKeys: string[] = Object.keys(data);
		const formattedFormData: IFormData = getFormattedFormData(
			data,
			dataKeys
		);

		handleFormSubmit(formattedFormData);
	};

	const defaultColor = isDisabled ? theme.text.disabled : theme.text.green1;
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormTopContent
				selectedID={selectedID}
				selectedTitle={selectedTitle}
				selectedIDContent={selectedIDContent}
			/>
			{formData.map((field) => (
				<FormFieldWrapDiv key={field.name}>
					<FormLabel htmlFor={field.name} isDisabled={isDisabled}>
						{field.label}*{" "}
						{errors[field.name]?.type === "minLength" && (
							<ErrorSpan>Minimum of 2 characters</ErrorSpan>
						)}
					</FormLabel>
					<FormInput
						aria-label={field.label}
						hasErrors={checkError(errors[field.name])}
						name={field.name}
						disabled={isDisabled}
						type={field.type}
						ref={register(field.validations)}
					/>
				</FormFieldWrapDiv>
			))}
			{formCheckboxes.map((field) => (
				<FormFieldCheckboxWrapDiv
					isDisabled={isDisabled}
					key={field.name}
				>
					<FormInputCheckbox
						className="visuallyhidden"
						id={field.name}
						hasErrors={checkError(errors[field.name])}
						name={field.name}
						type={field.type}
						disabled={isDisabled}
						ref={register(field.validations)}
					/>
					<FormCheckboxLabel htmlFor={field.name}>
						{watch(field.name) ? (
							<IconChecked color={defaultColor} />
						) : (
							<IconUnchecked color={defaultColor} />
						)}
						<FormCheckboxLabelSpan>
							{field.label}
							{field.name === "terms" && (
								<FormCheckboxLabelLink
									target="_blank"
									href="https://supernetball.com.au/sites/supernetball/files/2021-07/Suncorp%20Team%20Girls%20Moment%20TCs_FINAL.pdf"
									rel="noopener noreferrer"
								>
									{" "}
									here.
								</FormCheckboxLabelLink>
							)}
						</FormCheckboxLabelSpan>
					</FormCheckboxLabel>
					{field.name === "terms" && errors.terms && (
						<ErrorSpan>Please accept the terms</ErrorSpan>
					)}
				</FormFieldCheckboxWrapDiv>
			))}
			<FormFieldWrapDiv>
				<FormInputSubmit type="submit" disabled={isDisabled} />
			</FormFieldWrapDiv>
		</form>
	);
};
