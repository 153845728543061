import styled from "styled-components";
import {theme} from "assets/theme";

interface IFormInput {
	hasErrors?: boolean;
}

interface IFormMomentDiv {
	selectedID: number;
}

interface IIsDisabled {
	isDisabled: boolean;
}

export const ErrorSpan = styled.span`
	color: ${theme.form.error};
	text-transform: capitalize;
	font-size: 11px;
	font-family: ${theme.font.arial};
	font-weight: normal;
}
`;

export const FormWrapDiv = styled.div`
	background-color: ${theme.text.white};
	font-family: ${theme.font.radikal};
	display: flex;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

export const FormDiv = styled.div`
	box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 20px 0px;
	padding: 48px 40px;
	text-align: left;
	text-transform: uppercase;
	width: 50%;

	@media screen and (max-width: 768px) {
		padding: 20px;
		width: 100%;
	}
`;

export const FormTitleRightDiv = styled.div`
	background-color: ${theme.text.green2};
	border-radius: 5px;
	color: ${theme.text.white};
	display: flex;
	height: 40px;
	line-height: 40px;
	padding: 0 15px;
`;

export const FormTitleDiv = styled.div`
	color: ${theme.text.green2};
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 17px;
`;

export const FormInput = styled.input<IFormInput>`
	height: 60px;
	width: 100%;
	border: 1px solid;
	border-color: ${({hasErrors}) =>
		hasErrors ? theme.form.error : theme.borders.active};
	border-radius: 5px;
	background-color: ${theme.text.white};
	padding: 20px;
	color: ${theme.text.darkGrey};
	font-family: ${theme.font.arial};
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 16px;
	&:disabled {
		border-color: ${theme.borders.disabled};
		color: ${theme.text.disabled};
	}
`;

export const FormFieldCheckboxWrapDiv = styled.div<IIsDisabled>`
	color: ${({isDisabled}) =>
		isDisabled ? theme.text.disabled : theme.text.activeGrey};
	margin: 0 0 20px 0;
`;

export const FormCompanionUL = styled.ul`
	font-size: 20px;
	list-style-type: initial;
	padding: 20px;
`;

export const FormInputCheckbox = styled.input<IFormInput>``;

export const FormCheckboxLabel = styled.label`
	font-family: ${theme.font.radikal};
	font-size: 12px;
	letter-spacing: 0;
	line-height: 20px;
	text-transform: initial;
	display: flex;
`;

export const FormCheckboxLabelSpan = styled.span`
	display: block;
	padding: 5px 20px 0;
	@media screen and (max-width: 768px) {
		max-width: 240px;
	}
`;

export const FormCheckboxLabelLink = styled.a`
	margin-left: 2px;
	text-decoration: underline;
`;

const FormSubmitBaseStyle = `
	background-color: ${theme.buttons.primary};
	border: none;
	border-radius: 2px;
	margin: 40px 0;
	height: 40px;
	width: 200px;

	color: ${theme.text.white};
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 17px;
	text-align: center;
	text-transform: uppercase;
	:disabled {
		background-color: ${theme.buttons.disabled};
	}
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

export const FormInputSubmit = styled.input`
	${FormSubmitBaseStyle}
`;

export const FormInputSubmitLoader = styled.div`
	${FormSubmitBaseStyle}
`;

export const FormTitleItemDiv = styled.div`
	height: 40px;
	line-height: 40px;
`;

export const FormTitleLink = styled.a``;

const FormFieldWrap = styled.div`
	display: flex;
	flex-direction: column;
`;

export const FormFieldMomentWrapDiv = styled(FormFieldWrap)`
	margin: 20px 0;
`;

export const FormFieldWrapDiv = styled(FormFieldWrap)`
	margin-bottom: 40px;
	@media screen and (max-width: 600px) {
		margin-bottom: 25px;
	}
`;

export const FormMomentLabel = styled.label`
	color: ${theme.text.activeGrey};
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 1.63px;
	line-height: 12px;
`;

export const FormLabel = styled.label<IIsDisabled>`
	color: ${({isDisabled}) =>
		isDisabled ? theme.text.disabled : theme.text.activeGrey};
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 1px;
	line-height: 17px;
	padding-bottom: 8px;
`;

export const FormMomentDiv = styled.div<IFormMomentDiv>`
	color: ${({selectedID}) =>
		selectedID === 0 ? theme.form.disabled : theme.text.green2};
	font-size: 36px;
	font-weight: 700;
	letter-spacing: 0.9px;
	line-height: 40px;
`;

export const FormCompanion = styled.div`
	padding: 60px 40px 0;
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	@media screen and (max-width: 600px) {
		padding: 40px 20px;
	}
`;

export const FormCompanionTitleDiv = styled.div`
	color: ${theme.text.green2};
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 17px;
	text-align: left;
	text-transform: uppercase;
`;

export const FormCompanionVideoDiv = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	margin: 30px 0;
`;

export const FormCompanionIconWrapDiv = styled.div`
	display: flex;
`;

export const FormCompanionIconDiv = styled.div`
	background-color: #e7e7e7;
	border-radius: 50%;
	color: #fff;
	height: 70px;
	margin-right: 40px;
	line-height: 70px;
	width: 70px;
`;

export const FormCompanionMainDiv = styled.div`
	color: ${theme.text.green2};
	font-size: 28px;
	letter-spacing: 1.2px;
	line-height: 38px;
	padding: 40px 0;
	text-align: left;
`;

export const BoldSpan = styled.span`
	font-weight: 700;
`;

export const FormCompanionBottomDiv = styled.div`
	display: flex;
`;

export const FormCompanionBottomTextDiv = styled.div`
	color: ${theme.text.green2};
	font-size: 20px;
	letter-spacing: 1px;
	line-height: 28px;
	text-align: left;
`;

export const FormThanksDiv = styled.div`
	color: ${theme.text.black};
	font-family: ${theme.font.radikal};
	font-size: 20px;
	letter-spacing: 1px;
	line-height: 30px;
	text-transform: initial;
`;

export const FormErrorDiv = styled.div`
	color: ${theme.form.error};
	font-family: ${theme.font.radikal};
	font-size: 28px;
	letter-spacing: 1.2px;
	line-height: 38px;
	padding: 40px 0;
	text-align: left;
	text-transform: initial;
`;

export const SocialWrapDiv = styled.div`
	color: ${theme.text.green2};
	display: flex;
	justify-content: space-between;
	margin: 70px 0;
	height: 60px;
	@media screen and (max-width: 600px) {
		flex-direction: column;
		height: auto;
	}
`;
export const SocialTextDiv = styled.div`
	font-family: ${theme.font.radikal};
	font-size: 34px;
	font-weight: 700;
	letter-spacing: 0.85px;
	line-height: 60px;
	text-transform: uppercase;
	@media screen and (max-width: 600px) {
		font-size: 20px;
	}
`;
export const SocailIconsWrapDiv = styled.div`
	display: flex;
`;
export const SocailIconButton = styled.button`
	background-color: ${theme.text.white};
	border: 1px solid ${theme.text.green2};
	height: 60px;
	width: 60px;
	margin: 0 10px;
	display: flex;
	justify-content: center;
	padding: 8px;
`;

export const TooltipBubbleDiv = styled.div`
	width: 0;
	opacity: 0;
	visibility: hidden;

	position: absolute;
	right: calc(100% + 8px);
	top: 50%;
	transform: translateY(-100%);

	max-width: 300px;
	padding: 0 15px;
	border-radius: 5px;

	font-family: ${theme.font.radikal};
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;

	color: white;
	background-color: ${theme.buttons.primary};

	transition: width 0s linear 0.2s, transform 0.2s ease-in,
		opacity 0.2s ease-in, visibility 0s linear 0.2s;

	:after {
		content: "";

		position: absolute;
		top: 50%;
		left: 100%;
		transform: translateY(-50%);

		height: 0;
		width: 0;

		margin: 0;
		padding: 0;

		border-style: solid;
		border-width: 6px;
		border-color: transparent;
		border-left-color: ${theme.buttons.primary};
	}

	@media screen and (max-width: 600px) {
		font-size: 11px;
		padding: 0 10px;
	}
}
`;

export const TooltipLabelDiv = styled.div`
	color: ${theme.text.green2};
	font-family: ${theme.font.radikal};
	font-size: 14px;
	font-weight: 600;
	position: relative;
	text-transform: uppercase;
	transition: color 0.2s linear;

	:hover {
		font-weight: 700;

		${TooltipBubbleDiv} {
			width: calc(90vw - 65px);
			opacity: 1;
			visibility: visible;
			transform: translateY(-50%);
			transition: width 0s linear, visibility 0s linear,
				transform 0.2s ease-in, opacity 0.2s ease-in;
		}
	}
`;

export const PreSeasonTextDiv = styled.div`
	color: ${theme.text.green2};
	font-family: ${theme.font.radikal};
	font-size: 28px;
	letter-spacing: 1px;
	line-height: 38px;
	text-transform: initial;
`;

export const TeamGirlsImg = styled.img`
	max-width: 100%;
`;
