import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconChecked: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox={`0 0 ${rest.width} ${rest.height}`} fill="none" {...rest}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<circle id="Oval" fill={color} cx="15" cy="15" r="15" />
			<polygon
				id="Path"
				fill="#FFFFFF"
				points="12.3333333 21.3333333 7 16 9 14 12.3333333 17.3333333 21 8.66666667 23 10.6666667"
			/>
		</g>
	</SVG>
);

IconChecked.defaultProps = {
	width: 30,
	height: 30,
	color: "currentColor",
};
