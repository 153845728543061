import React from "react";
import styled from "styled-components";

interface IProps {
	color?: string;
}

const SVG = styled.svg`
	margin: 0 auto;
`;

export const IconArrowRight: React.FC<IProps> = ({color}) => (
	<SVG viewBox={`0 0 24 24`} fill="none">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				fill={color}
				points="8 16.6 12.6 12 8 7.4 9.4 6 15.4 12 9.4 18"
			/>
		</g>
	</SVG>
);

IconArrowRight.defaultProps = {
	color: "currentColor",
};
