import styled from "styled-components";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

export const Button = styled.button`
	background: #e90052;
	max-width: 220px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	font-size: 14px;
	border: 0;
	width: 100%;
	padding: 0;
	cursor: pointer;
	transition: all 0.5s;

	&:hover {
		opacity: 0.75;
	}

	&:disabled {
		background: #ddd;
		color: #bbb7c5;
		cursor: default;

		&:hover {
			opacity: 1;
		}
	}
`;

export const ButtonAlternate = styled(Button)`
	border: 1px solid #fff;
	color: #fff;
	background: rgba(255, 255, 255, 0.1);
`;

export const ButtonSecondary = styled(Button)`
	background: rgba(0, 0, 0, 0);
	border: 1px solid #e90052;
	color: #e90052;
`;
