import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg``;

export const IconUnchecked: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox={`0 0 ${rest.width} ${rest.height}`} fill="none" {...rest}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M15,0 C23.2842712,0 30,6.71572875 30,15 C30,23.2842712 23.2842712,30 15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 Z M15,2 C7.82029825,2 2,7.82029825 2,15 C2,22.1797017 7.82029825,28 15,28 C22.1797017,28 28,22.1797017 28,15 C28,7.82029825 22.1797017,2 15,2 Z"
				fill={color}
			/>
		</g>
	</SVG>
);

IconUnchecked.defaultProps = {
	width: 30,
	height: 30,
	color: "currentColor",
};
