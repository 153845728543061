import React from "react";
import MomentLogo from "assets/img/Moment.svg";
import {
	WrapperHeader,
	SuperNetballLinkDiv,
	SuperNetballLink,
	SuperNetballMobLink,
	HeaderLogoWrapDiv,
	HeaderLogoDiv,
	HeaderMomentDiv,
	HeaderMomentImg,
	HeaderDescriptionDiv,
	HeaderSponsorDiv,
	WrapperDiv,
	MobOnlyDiv,
} from "./HeaderStyle";

const HeaderDesc: React.FC = () => (
	<React.Fragment>
		<HeaderMomentDiv>
			<HeaderMomentImg src={MomentLogo} alt="Moment of the week" />
		</HeaderMomentDiv>
		<HeaderDescriptionDiv>
			We are celebrating the top displays of teamwork, resilience and
			spirit across the weekend. Choose your favourite Suncorp Team Girls
			Moment for your chance to win!
		</HeaderDescriptionDiv>
	</React.Fragment>
);
const backLink = "< Back to super netball";
const backLinkMob = "< Back";
export const Header: React.FC = () => {
	return (
		<WrapperHeader>
			<WrapperDiv>
				<SuperNetballLinkDiv>
					<SuperNetballLink
						href="https://supernetball.com.au/"
						target="_blank"
						rel="noopener noreferrer"
					>
						{backLink}
					</SuperNetballLink>
					<SuperNetballMobLink
						href="https://supernetball.com.au/"
						target="_blank"
						rel="noopener noreferrer"
					>
						{backLinkMob}
					</SuperNetballMobLink>
				</SuperNetballLinkDiv>
				<HeaderLogoWrapDiv>
					<HeaderLogoDiv title="Suncorp Team Girls" />
					<HeaderDesc />
				</HeaderLogoWrapDiv>
				<HeaderSponsorDiv title="Suncorp Super Netball" />
			</WrapperDiv>
			<MobOnlyDiv>
				<HeaderDesc />
			</MobOnlyDiv>
		</WrapperHeader>
	);
};
