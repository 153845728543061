import {
	HTTPClient as HTTPClientCore,
	IRequestConfig,
} from "@fanhubmedia/fe-common-utils";
import * as qs from "qs";
import axios, {AxiosError} from "axios";
import {CANCEL} from "redux-saga";
import {JSON_URL} from "modules/utils/constants";
import {ApiError} from "./ApiError";
import {ISweepStakesAnswers, IVoteSaveAnswers} from "modules/types";

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest<T>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const newConfig = {
			...config,
			cancelToken: source.token,
		};

		const request = super.makeRequest(newConfig);
		(request as any)[CANCEL] = () => source.cancel();

		return request;
	}
}

const onCatchNetworkError = (response: AxiosError) =>
	Promise.reject(response.response?.data).catch((err) => ApiError.CHECK(err));

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
	onCatchNetworkError,
});

const APIClient = new HTTPClient({
	baseURL: process.env.REACT_APP_API_URL || "",
	transformRequest: [(data) => qs.stringify(data)],
	onCatchNetworkError,
});

export const Api = {
	JSON: {
		help_pages: () => JSONClient.get("help_pages.json"),
		polling: () => JSONClient.get(`netballau_teamgirls/polling.json`),
		archive: () =>
			JSONClient.get(`netballau_teamgirls/archive/2020/polling.json`),
	},
	Polling: {
		saveSweepstakes: (params: ISweepStakesAnswers) =>
			APIClient.post("polling/sweepstakes", params),
		saveVote: (params: IVoteSaveAnswers) =>
			APIClient.post("polling/save", params),
	},
};

export * from "./ApiError";
