import PlayIcon from "assets/img/icons/Play.svg";
import styled from "styled-components";
import {theme} from "assets/theme";

export const CurrentRoundUl = styled.ul`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	min-height: 580px;
`;

export const NoVideosDiv = styled.div`
	color: ${theme.text.white};
	font-family: ${theme.font.radikal};
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 18px;
	margin-top: 10px;
	min-height: 580px;
`;

export const CurrentRoundLi = styled.li`
	background-color: ${theme.text.white};
	display: flex;
	flex-direction: column;
	height: 250px;
	margin: 0 20px 40px;
	max-width: 360px;
	width: 100%;
	&.vid-2 {
		height: 400px;
		max-width: 580px;
	}
	&.vid-3,
	&.vid-4 {
		margin: 0 40px 40px;
	}
	&:hover {
		transform: translateY(-16px);
	}
	@media screen and (max-width: 900px) {
		&.vid-2 {
			height: 250px;
			max-width: 360px;
		}
	}
	@media screen and (max-width: 600px) {
		margin: 0 5px 20px;
		&.vid-3,
		&.vid-4 {
			margin: 0 5px 20px;
		}
	}
`;

export const CurrentImageButton = styled.button`
	background-size: cover;
	background-repeat: no-repeat;
	border: none;
	height: calc(100% - 72px);
	margin: 2px 2px 0 2px;
	padding: 0;
	width: calc(100% - 4px);
`;

export const CurrentImagePlay = styled.span`
	background-image: url(${PlayIcon});
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 50%;
	display: inline-block;
	background-color: ${theme.text.white};
	height: 65px;
	width: 65px;
`;

export const MomentTextDiv = styled.div`
	color: ${theme.text.grey};
	font-family: ${theme.font.radikal};
	font-size: 10px;
	font-weight: 700;
	line-height: 12px;
	margin: 10px 0;
	text-align: left;
	text-indent: 10px;
	text-transform: uppercase;
`;

export const MomentTitleDiv = styled.div`
	color: ${theme.text.green1};
	font-family: ${theme.font.radikal};
	font-size: 14px;
	font-weight: 700;
	line-height: 17px;
	padding: 0 10px;
	text-transform: uppercase;
	text-align: left;
	&.show-mobile {
		display: none;
	}
	@media screen and (max-width: 600px) {
		&.show-mobile {
			display: block;
		}
		&.show-desktop {
			display: none;
		}
	}
`;

export const VideoDescriptionDiv = styled.div`
	display: flex;
	height: 72px;
	justify-content: space-between;
`;

export const VoteButtonWrapDiv = styled.div`
	background-color: #eef9f9;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 10px;
`;

export const VoteResultsDiv = styled.div`
	color: ${theme.text.green2};
	font-family: ${theme.font.radikal};
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 21px;
	text-align: center;
`;

export const VotePercentageWrapDiv = styled.div`
	background-color: #f2f2f2;
	width: 100%;
`;

export const VotePercentageDiv = styled.div`
	background-color: ${theme.text.green3};
	height: 5px;
`;
