import {SagaIterator} from "redux-saga";
import {all, takeLatest} from "redux-saga/effects";

import {
	globalError,
	fetchHelpPages,
	fetchPolling,
	submitSweepstakesForm,
	fetchPollingArchive,
} from "modules/actions";

import {
	errorsManagerSaga,
	fetchHelpPagesSaga,
	fetchPollingSaga,
	postSweepstakesForm,
	fetchPollingArchiveSaga,
} from "modules/sagas";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all([
		takeLatest(globalError, errorsManagerSaga),

		takeLatest(fetchHelpPages, fetchHelpPagesSaga),
		takeLatest(fetchPolling, fetchPollingSaga),
		takeLatest(fetchPollingArchive, fetchPollingArchiveSaga),

		takeLatest(submitSweepstakesForm, postSweepstakesForm),
	]);
};

export * from "./errors";
export * from "./help";
export * from "./polling";
