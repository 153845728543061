import React from "react";
import {
	FormTitleDiv,
	FormTitleItemDiv,
	FormFieldMomentWrapDiv,
	FormMomentLabel,
	FormMomentDiv,
	TooltipLabelDiv,
	TooltipBubbleDiv,
} from "./FormWrapStyle";

type IFormTopContent = {
	selectedID: number;
	selectedTitle: string;
	selectedIDContent: (
		selectedID: number,
		section: string,
		selectedTitle: string
	) => string;
};

export const FormTopContent: React.FC<IFormTopContent> = ({
	selectedID,
	selectedIDContent,
	selectedTitle,
}) => {
	return (
		<React.Fragment>
			<FormTitleDiv>
				<FormTitleItemDiv>You have Selected</FormTitleItemDiv>
				<FormTitleItemDiv>
					<TooltipLabelDiv>
						Help
						<TooltipBubbleDiv>
							Make your selection above
						</TooltipBubbleDiv>
					</TooltipLabelDiv>
				</FormTitleItemDiv>
			</FormTitleDiv>
			<FormFieldMomentWrapDiv>
				<FormMomentLabel>
					{selectedIDContent(
						selectedID,
						"momentLabel",
						selectedTitle
					)}
				</FormMomentLabel>
				<FormMomentDiv selectedID={selectedID}>
					{selectedIDContent(
						selectedID,
						"momentSelected",
						selectedTitle
					)}
				</FormMomentDiv>
			</FormFieldMomentWrapDiv>
		</React.Fragment>
	);
};
