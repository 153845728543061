import styled from "styled-components";
import {theme} from "assets/theme";
import {StatusTypes} from "modules/reducers";

interface IRoundButton {
	status: StatusTypes;
}

export const RoundNavDiv = styled.div`
	display: flex;
	justify-content: center;
	margin: 25px auto;
	&.show-mob {
		display: none;
	}
	@media (max-width: 640px) {
		&.show-mob {
			display: flex;
		}
		&.show-desktop {
			display: none;
		}
	}
`;

export const RoundNavUL = styled.ul`
	display: flex;
`;

export const RoundNavLi = styled.li`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const getRoundButtonColor = ({status}: IRoundButton) => {
	if (status === "current") {
		return theme.text.white;
	} else if (status === "scheduled") {
		return "#00706A";
	} else {
		return "transparent";
	}
};

export const RoundButton = styled.button<IRoundButton>`
	background-color: ${({status}) => getRoundButtonColor({status})};
	border-radius: 50%;
	border: 1px solid ${theme.text.white};
	color: ${({status}) => (status === "current" ? "#00706A" : "#FFF")};
	font-family: ${theme.font.radikal};
	font-weight: 700;
	height: 30px;
	letter-spacing: 1px;
	margin: 0 5px;
	opacity: ${({status}) => (status === "scheduled" ? "38%" : "100%")};
	width: 30px;
`;

export const RoundPrevNextButton = styled.button`
	background-color: transparent;
	color: ${theme.text.white};
	border: none;
	width: 60px;
	&:disabled {
		color: ${theme.text.disabled};
	}
`;
