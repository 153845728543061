import {isEmpty, get, size} from "lodash";
import {IApiResponse} from "modules/types";

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	public static USER_NOT_REGISTERED = 513;

	public static HAS_ERROR({errors}: IApiResponse) {
		return (
			!isEmpty(errors) || Boolean(Array.isArray(errors) && size(errors))
		);
	}

	/**
	 * Should be called after each requests when we expect some errors or validations
	 */
	public static CHECK(response: IApiResponse) {
		if (ApiError.HAS_ERROR(response)) {
			const text = get(
				response,
				"errors[0]",
				get(response, "errors.message")
			);
			throw new ApiError(text, response.code);
		}

		return response;
	}

	public code?: number;
	public text?: string;

	constructor(text: string, code?: number) {
		super(text);
		this.name = "API Error";
		this.text = text;
		this.code = code;
	}
}
