import styled from "styled-components";
import {theme} from "assets/theme";
import SuncorpLogo from "assets/img/Suncorp_Primary_Thick.svg";
import SuperNetballLogo from "assets/img/super-netball_logo_rgb_full.svg";

export const WrapperHeader = styled.header`
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;
	padding: 0 20px;
	@media screen and (max-width: 600px) {
		padding: 0;
		margin-bottom: 30px;
	}
`;

export const WrapperDiv = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const SuperNetballLinkDiv = styled.div`
	padding-top: 75px;
	width: 25%;

	@media screen and (max-width: 900px) {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-top: 0px;
	}
	@media screen and (max-width: 600px) {
		padding: 0 10px;
	}
`;

export const SuperNetballLinkBase = styled.a`
	color: #a0e9e0;
	font-family: ${theme.font.radikal};
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 12px;
	text-transform: uppercase;
`;

export const SuperNetballLink = styled(SuperNetballLinkBase)`
	@media screen and (max-width: 900px) {
		display: none;
	}
`;

export const SuperNetballMobLink = styled(SuperNetballLinkBase)`
	display: none;
	@media screen and (max-width: 900px) {
		display: block;
	}
`;

export const HeaderMomentDiv = styled.div`
	margin-top: 20px;
`;

export const HeaderMomentImg = styled.img`
	width: 100%;
`;

export const HeaderDescriptionDiv = styled.div`
	color: ${theme.text.white};
	font-family: ${theme.font.radikal};
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 18px;
	margin-top: 10px;
`;

export const HeaderLogoWrapDiv = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 350px;
	text-align: center;
	width: 50%;
	@media screen and (max-width: 900px) {
		& > ${HeaderMomentDiv}, > ${HeaderDescriptionDiv} {
			display: none;
		}
	}
	@media screen and (max-width: 600px) {
		width: 40%;
	}
`;

export const HeaderLogoDiv = styled.div`
	background: url(${SuncorpLogo}) no-repeat center center ${theme.text.white};
	background-size: 90%;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	height: 165px;
	display: flex;
	justify-content: center;
	width: 100%;
	@media screen and (max-width: 600px) {
		height: 75px;
	}
`;

export const HeaderSponsorDiv = styled.div`
	background: url(${SuperNetballLogo}) no-repeat center center transparent;
	background-size: 80%;
	height: 165px;

	text-align: right;
	width: 25%;
	@media screen and (max-width: 900px) {
		height: auto;
	}
	@media screen and (max-width: 600px) {
		display: flex;
		justify-content: center;
		width: 35%;
	}
`;

export const HeaderSponsorImg = styled.img`
	padding: 40px 0 0;
	max-width: 250px;
	width: 90%;
	@media screen and (max-width: 600px) {
		padding-top: 0;
	}
`;

export const MobOnlyDiv = styled.div`
	& > ${HeaderMomentDiv}, > ${HeaderDescriptionDiv} {
		display: none;
	}
	@media screen and (max-width: 900px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		padding-top: 50px;
		& > ${HeaderMomentDiv}, > ${HeaderDescriptionDiv} {
			display: block;
			margin: 0 auto;
			max-width: 320px;
		}
	}
	@media screen and (max-width: 600px) {
		& > ${HeaderMomentDiv} {
			max-width: 300px;
		}
		& > ${HeaderDescriptionDiv} {
			max-width: 300px;
		}
	}
`;
