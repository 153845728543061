import * as _ from "lodash";

const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";

const getFullMsg = (message: string) =>
	`My moment of the week is ${message}. Which one will you choose?`;
/**
 * in order to reduce the size of the string for sharing
 */
const getLinkForShare = () => FB_SHARE_URL;

export const shareFB = () => {
	const FB_API = _.get(window, "FB");

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(),
		},
		_.identity
	);
};

export const shareTwitter = (message: string) => {
	const href = getLinkForShare();

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(getFullMsg(message)),
		"twitter-share-dialog",
		"width=668,height=668"
	);
};
