import styled from "styled-components";
import {theme} from "assets/theme";

export const Preloader = styled.div`
	background-color: ${theme.text.white};
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	height: 250px;
	margin: 0 20px 40px;
	max-width: 360px;
	width: 100%;
`;
