import React from "react";
import {size} from "lodash";
import {IFormData, IPollingQuestionOptions, IFormError} from "modules/types";
import {FormContent} from "./FormContent";
import {FormComplete} from "./FormComplete";
import {FormCompanionWrap} from "./FormCompanionWrap";
import {FormWrapDiv, FormDiv} from "./FormWrapStyle";

interface IFormWrap {
	currentRoundIndex: number;
	roundLocked: boolean;
	formError: IFormError;
	formStatus: string;
	selectedItem: string;
	showVotes: boolean;
	winner: IPollingQuestionOptions;
	handleFormSubmit: (formData: IFormData) => void;
	videos: IPollingQuestionOptions[];
}

const getSelectedTitle = (
	videos: IPollingQuestionOptions[],
	selectedID: number
) => {
	const filteredVideos = videos.filter(
		(_video, videoIndex) => videoIndex + 1 === selectedID
	);

	return size(filteredVideos) ? filteredVideos[0].title : "";
};

export const FormWrap: React.FC<IFormWrap> = ({
	roundLocked,
	formError,
	formStatus,
	handleFormSubmit,
	selectedItem,
	showVotes,
	videos,
	winner,
}) => {
	const isDisabled = selectedItem === "" || formStatus === "saving";
	const selectedID = isDisabled ? 0 : Number(selectedItem.split("-")[2]);
	const selectedTitle = getSelectedTitle(videos, selectedID);
	const selectedIDContent = (
		mySelectedID: number,
		section: string,
		mySelectedTitle: string
	) => {
		const sections = {
			momentLabel: mySelectedID > 0 ? `Moment ${mySelectedID}` : "Moment",
			momentSelected: mySelectedID > 0 ? mySelectedTitle : "Nothing Yet!",
		};

		return sections[section] !== undefined ? sections[section] : null;
	};

	return (
		<FormWrapDiv>
			<FormDiv>
				{showVotes ? (
					<FormComplete
						roundLocked={roundLocked}
						formError={formError}
						selectedID={selectedID}
						selectedTitle={selectedTitle}
						selectedIDContent={selectedIDContent}
						winner={winner}
					/>
				) : (
					<FormContent
						handleFormSubmit={handleFormSubmit}
						isDisabled={isDisabled}
						selectedID={selectedID}
						selectedTitle={selectedTitle}
						selectedIDContent={selectedIDContent}
					/>
				)}
			</FormDiv>
			<FormCompanionWrap />
		</FormWrapDiv>
	);
};
