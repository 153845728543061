import {SagaIterator} from "redux-saga";
import {call, put} from "redux-saga/effects";
import {size} from "lodash";
import {Api, ApiError} from "modules/utils";
import {ISagaAction, ISubmitSweepstakes, IVoteSuccess} from "modules/types";

import {
	fetchPollingSuccess,
	fetchPollingArchiveSuccess,
	fetchPollingFailed,
	fetchPollingArchiveFailed,
	sweepstakesFormSuccess,
	sweepstakesFormFailed,
	voteFormSuccess,
} from "modules/actions";

export const fetchPollingSaga = function* () {
	try {
		const response = yield call(Api.JSON.polling);

		yield put(fetchPollingSuccess(response));
	} catch (e) {
		yield put(fetchPollingFailed(e));
	}
};

export const fetchPollingArchiveSaga = function* () {
	try {
		const response = yield call(Api.JSON.archive);

		yield put(fetchPollingArchiveSuccess(response));
	} catch (e) {
		yield put(fetchPollingArchiveFailed(e));
	}
};

const splitString = (str: string) =>
	str.substring(0, 3) + str.substr(size(str) - 5);

export const postSweepstakesForm = function* (
	action: ISagaAction<ISubmitSweepstakes>
): SagaIterator {
	try {
		const getUserKey = splitString(`${Date.now()}`);
		const response = yield call(Api.Polling.saveSweepstakes, {
			...action.payload.formData,
			user_key: getUserKey,
		});

		ApiError.CHECK(response);

		const isDisabled = action.payload.formData.selectedItem === "";
		const selectedID = isDisabled
			? 0
			: Number(action.payload.formData.selectedItem.split("-")[1]);

		const saveVoteResponse = yield call(Api.Polling.saveVote, {
			question_id: Number(action.payload.formData.question_id),
			option_id: selectedID,
			user_key: getUserKey,
		});

		yield put(sweepstakesFormSuccess());
		const voteResults: IVoteSuccess[] = saveVoteResponse.result;
		yield put(
			voteFormSuccess({
				results: voteResults,
				index: action.payload.index,
			})
		);
	} catch (e) {
		yield put(sweepstakesFormFailed({...e, index: action.payload.index}));
	}
};
