import React from "react";
import styled from "styled-components";

const OverLayWrapDiv = styled.div`
	background-color: rgba(3, 162, 154, 0.5);
	position: absolute;
	height: 250px;
	width: 360px;
	&.vid-2 {
		height: 400px;
		width: 580px;
	}
	@media screen and (max-width: 900px) {
		&.vid-2 {
			height: 250px;
			width: 360px;
		}
	}
`;

const OverlayVideoButton = styled.button`
	background: transparent;
	border: none;
	height: 175px;
	width: 100%;
`;

const OverlayVoteButton = styled.button`
	background: transparent;
	border: none;
	height: 70px;
	width: 100%;
`;

interface IVideoOverLay {
	choseVideo: () => void;
	openModal: () => void;
	selector: string;
}

export const VideoOverLay: React.FC<IVideoOverLay> = ({
	choseVideo,
	openModal,
	selector,
}) => (
	<OverLayWrapDiv className={selector}>
		<OverlayVideoButton aria-label="View Video" onClick={openModal} />
		<OverlayVoteButton aria-label="Select Video" onClick={choseVideo} />
	</OverLayWrapDiv>
);
